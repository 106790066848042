.App {
  text-align: center;
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.textHolder {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  pointer-events: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fade {
  
  animation: fadeIn 20s linear infinite alternate;
}
.fade1 {
  
  animation: fadeIn 10s linear infinite alternate;
}
.fade2 {
  
  animation: fadeIn 15s linear infinite alternate;
}
.fade3 {
  animation: fadeIn 30s linear infinite alternate;
}


@keyframes fadeIn {
  0% {
      opacity: 0;
      
  }
  60% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 600px) {
  .textHolder {
    font-size: 14px;
    
  }
}